exports.components = {
  "component---src-layouts-collection-jsx": () => import("./../../../src/layouts/collection.jsx" /* webpackChunkName: "component---src-layouts-collection-jsx" */),
  "component---src-layouts-page-js": () => import("./../../../src/layouts/page.js" /* webpackChunkName: "component---src-layouts-page-js" */),
  "component---src-layouts-post-js": () => import("./../../../src/layouts/post.js" /* webpackChunkName: "component---src-layouts-post-js" */),
  "component---src-layouts-product-jsx": () => import("./../../../src/layouts/product.jsx" /* webpackChunkName: "component---src-layouts-product-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-activate-js": () => import("./../../../src/pages/account/activate.js" /* webpackChunkName: "component---src-pages-account-activate-js" */),
  "component---src-pages-account-addresses-js": () => import("./../../../src/pages/account/addresses.js" /* webpackChunkName: "component---src-pages-account-addresses-js" */),
  "component---src-pages-account-details-js": () => import("./../../../src/pages/account/details.js" /* webpackChunkName: "component---src-pages-account-details-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("./../../../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-register-js": () => import("./../../../src/pages/account/register.js" /* webpackChunkName: "component---src-pages-account-register-js" */),
  "component---src-pages-account-request-activation-js": () => import("./../../../src/pages/account/request-activation.js" /* webpackChunkName: "component---src-pages-account-request-activation-js" */),
  "component---src-pages-account-reset-password-js": () => import("./../../../src/pages/account/reset-password.js" /* webpackChunkName: "component---src-pages-account-reset-password-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-refresh-js": () => import("./../../../src/pages/search-refresh.js" /* webpackChunkName: "component---src-pages-search-refresh-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-wishlist-jsx": () => import("./../../../src/pages/wishlist.jsx" /* webpackChunkName: "component---src-pages-wishlist-jsx" */),
  "component---src-templates-about-template-jsx": () => import("./../../../src/templates/about-template.jsx" /* webpackChunkName: "component---src-templates-about-template-jsx" */),
  "component---src-templates-careers-template-jsx": () => import("./../../../src/templates/careers-template.jsx" /* webpackChunkName: "component---src-templates-careers-template-jsx" */),
  "component---src-templates-contact-template-jsx": () => import("./../../../src/templates/contact-template.jsx" /* webpackChunkName: "component---src-templates-contact-template-jsx" */),
  "component---src-templates-credit-account-template-jsx": () => import("./../../../src/templates/credit-account-template.jsx" /* webpackChunkName: "component---src-templates-credit-account-template-jsx" */),
  "component---src-templates-design-services-template-jsx": () => import("./../../../src/templates/design-services-template.jsx" /* webpackChunkName: "component---src-templates-design-services-template-jsx" */),
  "component---src-templates-diy-hub-template-jsx": () => import("./../../../src/templates/diy-hub-template.jsx" /* webpackChunkName: "component---src-templates-diy-hub-template-jsx" */),
  "component---src-templates-estimating-template-jsx": () => import("./../../../src/templates/estimating-template.jsx" /* webpackChunkName: "component---src-templates-estimating-template-jsx" */),
  "component---src-templates-faq-template-jsx": () => import("./../../../src/templates/faq-template.jsx" /* webpackChunkName: "component---src-templates-faq-template-jsx" */)
}

