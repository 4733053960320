import Swiper from 'swiper';
import Next from '../assets/images/slide-arrow-next.svg';
import Prev from '../assets/images/slide-arrow-prev.svg';

/**
 * Mobile breakpoints and functions to check. Function like media queries
 */
export const breakpoints = {
  'xs': 576,
  'sm': 768,
  'md': 992,
  'lg': 1200,
  'xl': 1367,
  'xxl': 1550,
  'bigger': 1800,
}

export const sanitizeString = (string) => {
  return string.replace(/[|&!*#;$=%^_/?@":~<>()+,]/g, '');
}

/**
 * Converts strings into slugs/handles. i.e. "Hello World" > "hello-world"
 */
export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/(['"])$/, (_, m) => (m === "'" ? '-foot' : '-inch'));
  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

/**
 * Shopify metafields show up in an array of objects, with each object being a
 * field. This function organizes the fields into an object where the fields'
 * namespace is the key, and the value is an array of fields with that namespace
 */
export const parseShopifyMetafields = (metafields) => {
  let newMetafields = {};

  metafields?.forEach((field) => {
    const key = field.namespace || 'other';

    if (!newMetafields[key]) {
      newMetafields[key] = {};
    }

    let value = field.value;

    // Making heavy assumption that strings with this character are arrays
    if (!field.value.startsWith('{') && field.value.includes('|')) {
      value = field.value.split('|');
    }


    if (hasJsonStructure(field.value)) {
      value = JSON.parse(field.value);
      if (value.html) {
        value = value.html
      }
      if (Array.isArray(value)) {
        value = value.map((val) => {
          return val.html || val
        })
      }
    }

    newMetafields[key][field.key] = value;
  })

  return newMetafields;
}

const hasJsonStructure = (str) => {
  if (typeof str !== 'string') return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]'
      || type === '[object Array]';
  } catch (err) {
    return false;
  }
}

/**
 * Price Formatter
 */
export const priceFormatter = (num) => {
  if (!num) { return '$0.00' }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(num);
}

/**
 * Uses variant prices to determine whether it can be considered "on sale"
 */
export const variantIsOnSale = (variant) => {
  if (!variant.compareAtPrice) { return false }
  if (variant.compareAtPrice < variant.price) { return false }
  return true;
}

/**
 * Uses variant prices to determine whether it can be considered "on sale"
 */
export const productIsOnSale = (product) => {
  if (!product) { return false; }

  const hasSaleVariant = product.variants.filter((variant) => {
    return variantIsOnSale(variant)
  }).length > 0

  return hasSaleVariant;
}

/**
 * For some reason, Shopify's JSON-encoded API responses can't be parsed using
 * JSON.parse().
 * So this kinda... fixes it up i guess.
 * This is the second site I've used this on so I guess it works consistently?
 */
export const fixShopifyResponse = (response) => {
  if (!response) { return false }
  return JSON.parse('[' + response.replace('}"{', '},"{') + ']')[0]
}

export const isExternalLink = (url) => {
  const fixedUrl = removeHeadlessFromUrl(url);

  if(fixedUrl.endsWith('.pdf')) {
    return true;
  }

  if (
    typeof window === 'undefined' ||
    ['#', '/'].includes(fixedUrl) ||
    fixedUrl.startsWith('/') ||
    fixedUrl.startsWith('sms:') ||
    fixedUrl.startsWith('mailto:') ||
    fixedUrl.startsWith('tel:')
  ) {
    return false
  }

  const newUrl = new URL(fixedUrl, window.location.origin);

  return newUrl.origin !== window.location.origin;
}

export const removeHeadlessFromUrl = (url) => {
  if (
    typeof window === 'undefined' ||
    ['#', '/'].includes(url) ||
    url.startsWith('/') ||
    url.startsWith('sms:') ||
    url.startsWith('mailto:') ||
    url.startsWith('tel:')
  ) {
    return url
  }

  const newUrl = new URL(url, window.location.origin);
  const headlessUrl = new URL(process.env.GATSBY_WP_URL);

  if (newUrl.host === headlessUrl.host) {
    return newUrl.pathname;
  }

  return url;
}


/**
 * The design calls for image carousels in some posts. WP doesn't offer that
 * functionality, so instead, we're scrubbing the content for galleries and
 * converting those into carousels.
 */
export const wpGalleryToCarousel = (contentBox) => {
  if (!contentBox || typeof document === 'undefined') {
    return null
  }

  const galleries = contentBox.getElementsByClassName('gallery');

  if (galleries.length) {
    Array.from(galleries).forEach((gallery, index) => {
      const swiper = document.createElement('div');
      swiper.className = 'swiper';

      const wrapper = document.createElement('div');
      wrapper.className = 'swiper-wrapper';

      Array.from(gallery.children).forEach((slideContent) => {
        const slide = document.createElement('div');
        slide.className = 'swiper-slide';

        slide.appendChild(slideContent);
        wrapper.appendChild(slide);
      })

      swiper.appendChild(wrapper);

      const prevButton = document.createElement('div');
      prevButton.classList.add('swiper-button', 'swiper-button-prev')
      const prevIcon = document.createElement('img');
      prevIcon.setAttribute('src', Prev)
      prevButton.appendChild(prevIcon)
      swiper.appendChild(prevButton)

      const nextButton = document.createElement('div');
      nextButton.classList.add('swiper-button', 'swiper-button-next')
      const nextIcon = document.createElement('img');
      nextIcon.setAttribute('src', Next)
      nextButton.appendChild(nextIcon)
      swiper.appendChild(nextButton)

      // Get slides per view
      let perView = 3;
      gallery.classList.forEach((classname) => {
        if (classname.startsWith('columns-') && classname !== 'columns-default') {
          perView = classname.replace('columns-', '')
        }
      })

      contentBox.insertBefore(swiper, gallery);
      gallery.remove();

      const mySwiper = new Swiper(swiper, {
        spaceBetween: 28,
        slidesPerView: 1,
        allowTouchMove: true,
        loop: true,
        navigation: {
          nextEl: nextButton,
          prevEl: prevButton,
        },
        breakpoints: {
          768: {
            slidesPerView: perView,
          }
        },
      });

      // Manual navigation
      nextButton.addEventListener('click', () => {
        mySwiper.slideNext();
      });

      prevButton.addEventListener('click', () => {
        mySwiper.slidePrev();
      });
    });
  }
}



export const disableScroll = () => {
  if (typeof document === 'undefined') { return false }
  document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
  document.body.style.width = '100vw';
}

export const enableScroll = () => {
  if (typeof document === 'undefined') { return false }
  document.body.style.overflow = 'initial';
  document.body.style.position = null;
  document.body.style.width = null;
}

export const getLocalStorage = (value) => {
  try {
    return JSON.parse(localStorage.getItem(value))
  } catch (e) {
    return '';
  }
}

export const isSpecialLink = ( url ) => {
  const prefixes = ['sms:', 'mailto:', 'tel:'];
  return prefixes.some((s) => url.startsWith(s));
}

export const orderCollections = (collections) => {
  // Setting the order we want for the collections
  const map = [
    'Best Sellers and Gift Cards',
    'Lumber and Boards',
    'Trusses and Engineered Lumber',
    'Plywood, OSB, and Sheet Goods',
    'Windows, Doors, and Skylights',
    'Roofing and Gutters',
    'Siding',
    'Decking and Railing',
    'Fencing',
    'Ceiling Tiles and Grid',
    'Insulation and Soundproofing',
    'Drywall and Steel Studs',
    'Moulding and Millwork',
    'Cabinets, Vanities, and Countertops',
    'Flooring and Tile',
    'Concrete Products and Rock Salt',
    'Caulk, Adhesive, and Weatherproofing',
    'Paint and Stain',
    'Hardware and Fasteners',
    'Plumbing and Electrical',
    'Polyethylene, Tarps, and Contractor Bags',
    'Tools, Equipment, and Work wear',
    'Special Orders',
    'Delivery Rates and Services',
    'Cutting Services',
  ]

  let newCollections = [];

  // Using our map to reorder the collections
  map.forEach(title => {
    const foundCol = collections.find(col => col.title === title);
    if(foundCol) {
      newCollections.push(foundCol);
    }
  })

  // Adding all collections that were unaccounted for to the end
  collections.forEach(col => {
    if(!newCollections.map(newCol => newCol.title).includes(col.title)) {
      newCollections.push(col);
    }
  })

  return newCollections;
}

/**
 * Returns hierarchal collections with key .subs for subcollections
 */
export const collectionHierarchy = (allCollections) => {
  const mainSubs = [];

  const getSubCols = (collection) => {
    const metadata = collection.subCollections?.value;
    return metadata?.split('|') || [];
  }

  const createSubHierarchy = (collection) => {
    const subCols = allCollections.filter(col => getSubCols(collection).includes(col.handle))
    collection.subs = [];
    subCols.forEach(col => collection.subs.push(createSubHierarchy(col)))
    return collection;
  }

  const topLevelCols = allCollections
    .filter(col => {
      const type = col.collectionType?.value;
      return !type || type !== 'Bundle';
    })
    .filter(col => {
      return allCollections.filter(allCol => getSubCols(allCol).includes(col.handle)).length < 1;
    })

  topLevelCols.forEach(collection => {
    mainSubs.push(createSubHierarchy(collection));
  })

  return mainSubs;
}

/**
 * Returns collections but with handle replaced with nested sub-collection path
 */
export const collectionNestedPaths = (allCollections) => {
  if(!allCollections) return false;

  const collectionBase = '';
  const newCollections = [];

  const recursiveColPageBuilder = (subs, prevPath) => {
    subs.forEach(sub => {
      const path = prevPath ? `${prevPath}/${sub.handle}` : sub.handle;

      if(!newCollections.find(col => col.id === sub.id)) {
        newCollections.push({ ...sub, handle: path })
      }

      if(sub.subs) {
        recursiveColPageBuilder(sub.subs, path)
      }
    })
  }

  recursiveColPageBuilder(collectionHierarchy(allCollections), collectionBase);

  return newCollections;
}

/**
 * Returns collections but with handle replaced with nested sub-collection path...
 * but hierarchal this time
 */
export const hierarchalCollectionNestedPaths = (allCollections) => {
  if(!allCollections) return false;

  const collectionBase = '';

  const recursiveColPageBuilder = (subs, prevPath) => {
    return subs.map(col => {
      const newHandle = prevPath ? `${prevPath}/${col.handle}` : col.handle;
      const updatedSubs = col.subs ? recursiveColPageBuilder(col.subs, newHandle) : [];

      return {
        ...col,
        handle: newHandle,
        subs: updatedSubs
      };
    });
  }

  return recursiveColPageBuilder(collectionHierarchy(allCollections), collectionBase);
}

/**
 * Gets only current and parent collections recursively using passed handle
 */
export const findParentHierarchy = (collections, targetHandle) => {
  function findInSubs(subs, targetHandle) {
    for (let sub of subs) {
      if (sub.handle === targetHandle) {
        return [{ ...sub, subs: [] }];
      }
      const result = findInSubs(sub.subs, targetHandle);
      if (result) {
        return [{ ...sub, subs: result }];
      }
    }
    return null;
  }

  for (let col of collectionHierarchy(collections)) {
    if (col.handle === targetHandle) {
      return [{ ...col, subs: [] }];
    }
    const result = findInSubs(col.subs, targetHandle);
    if (result) {
      return [{ ...col, subs: result }];
    }
  }

  return [];
}
