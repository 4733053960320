import fetch from 'isomorphic-fetch'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2024-04/graphql.json`,
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
      'Accept' : 'application/graphql'
    },
    fetch,
  }),
})

export default client;
